import * as Sentry from '@sentry/sveltekit'
import { handleErrorWithSentry } from '@sentry/sveltekit'

Sentry.init({
    dsn: 'https://b8a7cddf1d6a7ced93b2c3ed88854806@o4507838639243264.ingest.us.sentry.io/4507979604492288',
    environment: process.env.NODE_ENV === 'production' ? 'production' : 'development',
    enableTracing: true,
    tracesSampleRate: 1.0
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
